import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetTimestamp }) => {
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetTimestamp - now;
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60),
        totalSeconds: Math.floor(difference / 1000),
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const totalTargetSeconds = (targetTimestamp - new Date().getTime()) / 1000;
  const progress = timeLeft.totalSeconds ? (timeLeft.totalSeconds / totalTargetSeconds) * 100 : 0;
  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (interval !== 'totalSeconds' && timeLeft[interval]) {
      timerComponents.push(
        <span key={interval}>
          {timeLeft[interval]} {interval}{" "}
        </span>
      );
    }
  });

  return (
    <div>
      <div className="mb-0">
        <h6>
          <div style={{ fontWeight: 400, display: "inline-block", marginBottom: "6px" }} className='me-2'>
          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
          </div>
          
        </h6>
      </div>
    </div>
  );
};

export default CountdownTimer;
