import React, { useState } from 'react';
import { Button, Image, Modal, Badge, Row, Col } from 'react-bootstrap';
import SETTINGS from '../SETTINGS';

const CraftBook = () => {
    const [showModal, setShowModal] = useState(false);
    const [nodeInfoData, setNodeInfoData] = useState([]);

    const handleButtonClick = () => {
        const nodeInfoArray = Object.values(SETTINGS.NODE_INFO);
        setNodeInfoData(nodeInfoArray);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <div className="text-center">
            <Button onClick={handleButtonClick} className='buton buton-secondary' style={{ background: 'rgba(23, 181, 2, 1)' }} >
                CRAFT BOOK
            </Button>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 className='mb-0'>Craft Book</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {nodeInfoData.map((node, index) => (
                        <div className='pokemon-card mb-3 p-3 chat-message' key={index} style= {{background: 'rgba(0, 0, 0, 1)',border: '1px solid rgba(69, 58, 55, 1)'}}>
                            <Row className="align-items-center">
                                {/* Img */}
                                <Col sm={3} xs={8} className='offset-sm-0 offset-2'>
                                    <Image src={node.imgUrl} rounded className="w-100" />
                                </Col>
                                {/* Price / Items needed */}
                                <Col sm={8}>
                                    <h5 className='text-uppercase mb-md-3 mb-2 mt-2'>{node.title}</h5>
                                    {node.price > 0 ? (
                                        <div className='mt-sm-0 mt-2'>
                                            <h6><small>Price: </small></h6>
                                            <p>{node.price} {node.buyCurrency}</p>
                                        </div>
                                    ) : (
                                        <div className='mt-sm-0 mt-2 mb-lg-0 mb-3'>
                                            <h6><small>Items required for crafting: </small></h6>
                                            {node.craftElements.map((imgMod, index1) => (
                                                <Image key={index1} src={imgMod} rounded className="me-2 mb-md-0 mt-1" style={{ width: '40px', height: '40px' }} />
                                            ))}
                                        </div>
                                    )}
                                    {/* Rewards */}
                                    <Row className='mt-lg-0 mt-3 mb-1'>
                                        <Col xs={12} lg={4} className="mt-lg-3 mt-0">
                                            <Badge pill bg="primary" className='d-block mb-2 pill-blue'>
                                                <small>Daily: </small> {node.dailyRewards.toFixed(2)} {SETTINGS.tokenSymbol}
                                            </Badge>
                                        </Col>
                                        <Col xs={12} lg={4} className="mt-lg-3 mt-0">
                                            <Badge pill bg="info" className='d-block mb-2'>
                                                <small>Referral: </small> {node.refferalPercent} %
                                            </Badge>
                                        </Col>
                                        <Col xs={12} lg={4} className="mt-lg-3 mt-0">
                                            <Badge pill bg="primary" className='d-block mb-2 pill-pink'  style={{ background: 'rgba(190, 35, 1, 1)'}}>
                                                <small>Airdrop power: </small> {node.airdrop_power}
                                            </Badge>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer className='justift-content-center mt-3'>
                    <Button variant="buton" className="buton" onClick={handleClose} style={{ maxWidth: "400px" }}>
                        CLOSE
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CraftBook;
