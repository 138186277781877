import React, { useState, useEffect } from 'react';
import { Button, Spinner, Modal, Table } from 'react-bootstrap';
import moment from 'moment';
import NodesABI from '../abis/NodesABI';
import SETTINGS from '../SETTINGS';
import { BrowserProvider, Contract, formatUnits } from 'ethers';


const Transactions = ({ provider }) => {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const fetchEvents = async () => {
        try {
            setLoading(true);
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const signerAddress = await signer.getAddress();
            const contract = new Contract(
                SETTINGS.nodeContract,
                NodesABI,
                signer
            );
            const currentBlock = await ethersProvider.getBlockNumber();
            const blockSize = 4000;
            let fromBlock = 57446842;
            let allEvents = [];
            while (fromBlock <= currentBlock) {
                const toBlock = Math.min(fromBlock + blockSize, currentBlock);
                const eventFilter = contract.filters.ReferralReward();
                const events = await contract.queryFilter(eventFilter, fromBlock, toBlock);
                allEvents = [...allEvents, ...events];
                fromBlock = toBlock + 1;
            }
            // Decode and filter the event logs
            const decodedEvents = await Promise.all(allEvents.map(async (event) => {
                const parsedLog = contract.interface.parseLog(event);
                const block = await ethersProvider.getBlock(event.blockNumber);
                return {
                    user: parsedLog.args.user,
                    referrer: parsedLog.args.referrer,
                    usdcValue: formatUnits(parsedLog.args.usdcValue, 6), // Convert BigNumber to string
                    timestamp: block.timestamp,
                };
            }));
            const filteredEvents = decodedEvents.filter(event => event.referrer.toLowerCase() === signerAddress.toLowerCase());
            setEvents(filteredEvents);
            // Listen for new events
            contract.on('ReferralReward', async (...eventData) => {
                const parsedLog = contract.interface.parseLog({ data: eventData });
                const block = await ethersProvider.getBlock(eventData[1].blockNumber); // Assuming eventData[1] has the log information
                const newEvent = {
                    user: parsedLog.args.user,
                    referrer: parsedLog.args.referrer,
                    usdcValue: formatUnits(parsedLog.args.usdcValue, 6),
                    timestamp: block.timestamp,
                };
                if (newEvent.referrer.toLowerCase() === signerAddress.toLowerCase()) {
                    setEvents((prevEvents) => [...prevEvents, newEvent]);
                }
            });
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };
    const handleButtonClick = () => {
        setShowModal(true);
        fetchEvents();
    };
    const handleClose = () => {
        setShowModal(false);
    };


    return (
        <div>
            <center>
                <Button variant="primary" onClick={handleButtonClick} className='buton buton-secondary mt-2'>TRANSACTIONS</Button>
            </center>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h3 className='my-0'>Referral transactions</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status" className='loaderBig' />
                        </div>
                    ) : (
                        <Table striped bordered hover className='table'>
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>USDC Value</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {events.map((event, index) => (
                                    <tr key={index}>
                                        <td style={{ wordBreak: "break-all" }}>{event.user}</td>
                                        <td>{event.usdcValue}</td>
                                        <td>{moment.unix(event.timestamp).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
                <Modal.Footer className='pb-1 pt-3'>
                    <Button variant="buton buton-secondary" className="buton" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Transactions;
