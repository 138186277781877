import React from 'react';
import SETTINGS from '../SETTINGS';

const NodeSaleAnnouncement = () => {
  return (
    <div style={{ textAlign: 'left', }}>
    <h2 className='text-start'>🌐 LAND SALE IS HERE! 📣</h2>

    <p>Hey FMVerse fam! We're super excited to announce the launch of the LANDs sale. 🚀</p>

    <h4 className='mt-4 mb-2'>WHAT IS THE LANDS SALE? 🤔</h4>
    <p>The LANDs sale offers you a chance to buy the FMVerse node and contribute to the FMVerse token DEX liquidity pool. By owning LANDs, you get benefits and become a player in the network's operations.🛠️</p>

    <h4 className='mt-4 mb-2'>WHY ARE WE LAUNCHING LANDS? 🌟</h4>
    <p>At FMVerse, our mission is to make Web3 mainstream. We’re building a gamified experience to bring new users to Web3. This collaborative approach is all about shaping the future of Web3 commerce together. 🌍✨</p>

    <h4 className='mt-4 mb-2'>KEY DETAILS ABOUT THE LANDS SALE 🗓️</h4>
    <p>The Node Sale kicks off before the {SETTINGS.tokenSymbol} 1.0 launch. Here’s the scoop:</p>
    <ul>
      <li><strong>Total LANDs Available:</strong> 20,000</li>
      <li><strong>LANDs Rewards:</strong> 25% of the total FMVerse token supply is allocated for LAND creation, along with additional community perks. 💰</li>
      <li><strong>Pricing Mechanism:</strong> The sale has 10 tiers, with prices going up each tier. 📈</li>
    </ul>

    <h4 className='mt-4 mb-2'>BENEFITS OF OWNING A LAND 🎁</h4>
    <p>Joining the LANDs sale gets you these benefits:</p>
    <ul>
      <li><strong>Rewards:</strong> Keep earning rewards by holding LANDs. 🏆</li>
      <li><strong>Airdrop power:</strong> Get opportunities for more airdrops. 🎉</li>
      <li><strong>Secondary market:</strong> LAND NFTs can be bought, sold, or traded on secondary markets. 🔄</li>
    </ul>

    <h4 className='mt-4 mb-2'>BE PART OF THE {SETTINGS.tokenSymbol} MISSION🚀</h4>
    <p>FMVerse invites you to be a part of our mission to bring Web3 to mainstream through engaging and gamified experiences. 🌟 Discover new possibilities and shape the future with us!</p>
  </div>

  );
};

export default NodeSaleAnnouncement;
