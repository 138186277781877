import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Col, Card, Row, Table, Modal } from 'react-bootstrap';
import { FaInfoCircle, FaLock, FaCoins, FaChartPie, FaChartBar } from 'react-icons/fa';
import SETTINGS from '../SETTINGS';
import { ERC20_ABI } from "../abis/erc20";
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import Tokenomics from '../qvrs-tokenomics.png';

ChartJS.register(ArcElement, Tooltip, Legend);

function TokenInformation({ tokenData }) {
    return (
        <Card className="mb-3 custom-card" style={{backgroundColor:"rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
            <Card.Body>
                <Card.Title style={{color: "#fff"}}><FaInfoCircle className="me-2" style={{color: "#fff"}}/>Token Information</Card.Title>
                <div className="table-responsive">
                    <Table striped bordered hover>
                        <tbody>
                            <tr style={{backgroundColor:"rgba(12, 13, 8, 1)",border: "0px"}}>
                                <td style={{color: "rgba(165, 146, 142, 1)",border: "0px"}}><strong>Token Name:</strong></td>
                                <td style={{color: "rgba(255, 255, 255, 1)",border: "0px"}}>{tokenData.name}</td>
                            </tr>
                            <tr style={{border: "0px"}}>
                                <td style={{color: "rgba(165, 146, 142, 1)",border: "0px"}}><strong>Token Symbol:</strong></td>
                                <td style={{color: "rgba(255, 255, 255, 1)",border: "0px"}}>{tokenData.symbol}</td>
                            </tr>
                            <tr style={{backgroundColor:"rgba(12, 13, 8, 1)",border: "0px"}}>
                                <td style={{color: "rgba(165, 146, 142, 1)",border: "0px"}}><strong>Token Decimals:</strong></td>
                                <td style={{color: "rgba(255, 255, 255, 1)",border: "0px"}}>{tokenData.decimals}</td>
                            </tr>
                            <tr style={{border: "0px"}}>
                                <td style={{color: "rgba(165, 146, 142, 1)",border: "0px"}}><strong>Total Supply:</strong></td>
                                <td style={{color: "rgba(255, 255, 255, 1)",border: "0px"}}>{tokenData.totalSupply} {SETTINGS.tokenSymbol}</td>
                            </tr>
                            <tr style={{backgroundColor:"rgba(12, 13, 8, 1)",border: "0px"}}>
                                <td style={{color: "rgba(165, 146, 142, 1)",border: "0px"}}><strong>Price (USDC):</strong></td>
                                <td style={{color: "rgba(255, 255, 255, 1)",border: "0px"}}>{tokenData.price} USDC</td>
                            </tr>
                            <tr style={{border: "0px"}}>
                                <td style={{color: "rgba(165, 146, 142, 1)",border: "0px"}}><strong>Market Cap (USD):</strong></td>
                                <td style={{color: "rgba(255, 255, 255, 1)",border: "0px"}}>{tokenData.marketCap} USD</td>
                            </tr>
                            <tr style={{backgroundColor:"rgba(12, 13, 8, 1)",border: "0px"}}>
                                <td style={{color: "rgba(165, 146, 142, 1)",border: "0px"}}><strong>Contract Address:</strong></td>
                                <td style={{color: "rgba(255, 255, 255, 1)",border: "0px",color: "rgba(23, 181, 2, 1)"}}><a style={{color:"rgba(23, 181, 2, 1)",textDecoration: "none"}} href={`https://polygonscan.com/address/${tokenData.contractAddress}`} target='_blank' rel="noopener noreferrer">{tokenData.contractAddress}</a></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
}

function Contracts({ totalLocked, circulating, holderContracts }) {
    return (
        <Card className="mb-3 custom-card" style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
            <Card.Body>
                <Card.Title style={{color: "#FFF"}}><FaLock className="me-2" />Contracts</Card.Title>
                <Card.Text>
                    <b>Max supply:</b> 1,000,000,000.00 {SETTINGS.tokenSymbol}<br />
                    <b>Total locked:</b> {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalLocked)} {SETTINGS.tokenSymbol}<br />
                    <b>Circulating:</b> {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(circulating)} {SETTINGS.tokenSymbol}
                </Card.Text>
                <div className="table-responsive">
                    <Table striped bordered hover className='mt-md-4 mt-2'>
                        <thead>
                            <tr style={{border: "1px solid rgba(12, 13, 8, 1)"}}>
                                <th style={{color: "rgba(255,255,255, 1)"}}>Name</th>
                                <th style={{color: "rgba(255,255,255, 1)"}}>Balance</th>
                                <th style={{color: "rgba(255,255,255, 1)"}}>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {holderContracts.map((item, index) => (
                                <tr key={index} style={{border: "1px solid rgba(12, 13, 8, 1)"}}>
                                    <td style={{color: "rgba(165, 146, 142, 1)"}}>{item.name}</td>
                                    <td style={{color: "rgba(255, 255, 255, 1)"}}>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.balance)} {SETTINGS.tokenSymbol}</td>
                                    <td style={{color: "rgba(255, 255, 255, 1)"}}><a className='noblack' href={`https://polygonscan.com/address/${item.address}`} target='_blank' rel="noopener noreferrer">{item.trunAddress}</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
}

const Info = ({ provider, isConnected }) => {
    const [holderContracts, setHolderContracts] = useState([]);
    const [totalLocked, setTotalLocked] = useState(0);
    const [circulating, setCirculating] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const tokenData = {
        name: 'Finger Token',
        symbol: SETTINGS.tokenSymbol,
        decimals: 18,
        totalSupply: '1,000,000,000.00',
        price: '0.15',
        marketCap: '0.00',
        contractAddress: SETTINGS.tokenAddress
    };

    const data = {
        labels: [
            'Presale 1',
            'Presale 2',
            'Liquidity',
            'Marketing',
            'Team',
            'LANDS',
            'Reservedity'
        ],
        datasets: [
            {
                data: [4, 6, 10, 10, 10, 50, 10],
                backgroundColor: [
                    'rgba(148, 77, 255, 0.9)',
                    'rgba(148, 77, 255, 0.8)',
                    'rgba(148, 77, 255, 0.7)',
                    'rgba(148, 77, 255, 0.6)',
                    'rgba(148, 77, 255, 0.5)',
                    'rgba(148, 77, 255, 0.4)',
                    'rgba(148, 77, 255, 0.3)'
                ],
                hoverBackgroundColor: [
                    'rgba(148, 77, 255, 1)',
                    'rgba(148, 77, 255, 0.9)',
                    'rgba(148, 77, 255, 0.8)',
                    'rgba(148, 77, 255, 0.7)',
                    'rgba(148, 77, 255, 0.6)',
                    'rgba(148, 77, 255, 0.5)',
                    'rgba(148, 77, 255, 0.4)'
                ],
                borderColor: 'transparent'
            }
        ]
    };

    const tokenDistributionData = [
        { category: 'Liquidity', tokens: '100 M', percent: '10 %' },
        { category: 'Marketing', tokens: '100 M', percent: '10 %' },
        { category: 'Team', tokens: '100 M', percent: '10 %' },
        { category: 'LANDS', tokens: '500 M', percent: '50 %' },
        { category: 'Reserve', tokens: '100 M', percent: '10 %' }
    ];
    

    const holders = [
        { name: 'Land rewards', address: SETTINGS.nodeContract },
        { name: 'Staking', address: SETTINGS.stakingContract },
        { name: 'Airdops', address: "0x2762d07F253a312bC0738d80aB825afF3425aA0B" },
        { name: 'Liquidity', address: '0x94DbA1C4BeE08de2326D82d03399e4cca4CfB60c' },
        { name: 'Marketing', address: '0xE6dE7a00415A0e1c8aF157c6d2e61f1f74240589' },
        { name: 'Team', address: '0x296aF379bDd4d19FAd405ce70D6A80CCba769968' },
        { name: 'Reserve', address: '0xDe674095d2e3621073e332C75A03f1e1532520Ca' },
    ];

    async function getBalances() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        let holdersArr = [];
        let allBalanceAmount = 0;
        for (let i = 0; i < holders.length; i++) {
            const balance = await TokenContract.balanceOf(holders[i].address);
            const trunAddress = `${holders[i].address.slice(0, 6)}...${holders[i].address.slice(-1 * (5))}`;
            holdersArr.push({
                name: holders[i].name,
                address: holders[i].address,
                trunAddress: trunAddress,
                balance: formatUnits(balance, 18)
            });
            allBalanceAmount += parseFloat(formatUnits(balance, 18));
        }
        setHolderContracts(holdersArr);
        setTotalLocked(allBalanceAmount.toFixed(2));
        setCirculating((1000000000 - allBalanceAmount).toFixed(2));
    }

    useEffect(() => {
        if (!provider) return;
        getBalances();
    }, [provider]);

    return (
        <div className="info-container">
            <Row className="g-4">
                {/* Title */}
                <Col xs={12} className='mt-md-5 mt-4'>
                    <h2 className='text-start'>Fmverse token ({SETTINGS.tokenSymbol}) INFO</h2>
                </Col>
                {/* Token info */}
                <Col xs={12} lg={7} className='mt-md-3 mt-2'>
                    <TokenInformation tokenData={tokenData} />
                </Col>
                {/* Tokenomics */}
                <Col xs={12} lg={5} className='mt-md-3 mt-0'>
                    
                    <Card className="mb-3 custom-card" style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
                        <Card.Body>
                            <Card.Title className='mb-3' style={{color: "#fff"}}><FaChartPie className="me-2" />{SETTINGS.tokenSymbol} token Distribution</Card.Title>
                            <Pie data={data} />
                        </Card.Body>
                    </Card>
                </Col>
                {/* Distribution  */}
                <Col xs={12} lg={8} className='mt-md-3 mt-0'>
                    <Card className="mb-3 custom-card" style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
                        <Card.Body>
                            <Card.Title style={{color: "#fff"}}><FaChartBar className="me-2" />{SETTINGS.tokenSymbol} token Distribution</Card.Title>
                            <Table striped bordered hover className='mb-4 mt-4'>
                                <thead>
                                    <tr style={{border: "1px solid rgba(12, 13, 8, 1)"}}>
                                        <th style={{color: "#fff",border: "0px"}}>Category</th>
                                        <th style={{color: "#fff",border: "0px"}}>Tokens</th>
                                        <th style={{color: "#fff",border: "0px"}}>Percent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tokenDistributionData.map((token, index) => (
                                        <tr key={index} style={{border: "1px solid rgba(12, 13, 8, 1)"}}>
                                            <td style={{color: "rgba(165, 146, 142, 1)",border: "0px"}}>{token.category}</td>
                                            <td style={{color: "#fff",border: "0px"}}>{token.tokens}</td>
                                            <td style={{color: "#fff",border: "0px"}}>{token.percent}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                {/* Contracts */}
                <Col xs={12} lg={8} className='mt-md-3 mt-0 mb-4'>
                    <Contracts totalLocked={totalLocked} circulating={circulating} holderContracts={holderContracts} />
                </Col>
            </Row>
            {/* Modal for displaying enlarged image */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img src={Tokenomics} alt="Zoomed Tokenomics" style={{ width: '100%' }} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Info;