// src/components/Nodes.js
import React, { useState } from 'react';
import { Button, Spinner, Card, ProgressBar, Badge } from 'react-bootstrap';
import { BrowserProvider, Contract } from 'ethers'
import NodesABI from '../abis/NodesABI';
import SETTINGS from '../SETTINGS';

const CardNode = ({ nft, provider, getBalanceFunction, amount = 0 }) => {
  const [loading, setLoading] = useState(false);
  const [buyAvailable, setBuyAvailable] = useState(false);
  const [txMessage, setTxMessage] = useState('');
  const getProgress = (timestamp) => {
    const now = Math.floor(Date.now() / 1000);
    const timePassed = now - timestamp;
    const progress = (timePassed / 86400) * 100;
    return Math.min(progress, 100);
  };

  const claim = async () => {
    try {
      setLoading(true);
      setTxMessage("Claiming...");
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const nftContract = new Contract(
        SETTINGS.nodeContract,
        NodesABI,
        signer
      );

      const claimTx = await nftContract.claimRewards(nft.tokenId);
      await claimTx.wait();
    } catch (error) {
      console.error("Error claiming:", error);
      setTxMessage("Failed to Claim. Please try again.");
    } finally {
      nft.unclaimedRewards = 0;
      nft.lastClaim = Math.floor(Date.now());
      await getBalanceFunction();
      setLoading(false);

      // Optionally reset the transaction message after a delay
      setTimeout(() => setTxMessage(""), 5000);

    }

  };
  if (loading) {
    return (
      <div className="pokemon-card">
        <div className="text-center">
          <center>
            <Spinner animation="border" className='loaderBig' style={{ marginTop: "30px" }} role="status" />
            <p className='loaderMsg'>{txMessage}</p>
          </center>
        </div>
      </div>
    );
  }

  return (
    <Card className='card-fix text-center' style={{ backgroundColor: "rgba(0, 0, 0, 0.8)",height: "240px" }}>
      <Card.Body>
        <Card.Title className='h6 mt-md-0 mt-2'>
          {nft.name}
        </Card.Title>
        <Card.Img variant="top" src={nft.imageURL} alt={`LAND ${nft.tokenId}`}/>
        
        {nft.dailyReward > 0 ? (<><span className='tokenCardId' style={{opacity: "1",backgroundColor: "rgba(32, 114, 23, 1)"}}><small>ID:</small>{nft.tokenId}</span>
          {nft.unclaimedRewards > 0 ?
            (<Button variant="primary" onClick={claim} className="buton buton-secondary" style={{ marginTop: "12px",backgroundColor: "rgba(255, 184, 0, 1)",color: "#000" }}>
              CLAIM {nft.unclaimedRewards}
            </Button>) : (
              <div>
                <Button variant="primary" disabled className="buton buton-disabled buton-secondary">
                  <b><small>CLAIM {nft.dailyReward} {SETTINGS.tokenSymbol}</small></b>
                </Button>
                <ProgressBar
                  style={{ height: "5px", marginTop: "5px" }}
                  animated
                  striped
                  now={getProgress(nft.lastClaim)}
                />
              </div>
            )}
        </>) : (<div className='mt-1 mb-2'> <Badge pill bg="info" className='me-1 px-3'> {amount} </Badge></div>)}
      </Card.Body>
    </Card>
  );

};

export default CardNode;
