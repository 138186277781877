// src/components/Nodes.js

import React, { useState, useEffect } from 'react';
import { BiHome, BiPlanet, BiShoppingBag, BiInfoCircle, BiBox, BiCustomize } from 'react-icons/bi';
import { Button, Spinner, Badge, Alert, Col, Row } from 'react-bootstrap';
import copyImg from '../copy.png';
import StripeLoaderImg from '../stripe_loader.svg';
import CollectionABI from '../abis/CollectionABI';
import SETTINGS from '../SETTINGS';
import { BrowserProvider, Contract } from 'ethers'
import Transactions from '../components/Transactions';
import { useNavigate } from 'react-router-dom';
import GLBViewer from 'react-three-viewer';

const LevelComponent = ({ provider, isConnected, nodeAmount }) => {
    const [loading, setLoading] = useState(true);
    const [setTxMessage] = useState('');
    const [refferalCode, setRefferalCode] = useState('');
    const [userLevel, setUserLevel] = useState(1);

    const [levelModelUrl, setLevelModelUrl] = useState("https://node.fingermonkeys.com/planet.png");
    const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
    const navigate = useNavigate();
    const openCraft = () => {
        navigate('/craft');
    }
    const openBuyNode = () => {
        navigate('/store');
    }
    const _setReffCode = async (code) => {
        setRefferalCode(code);
    };
    const getUserLevel = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const collectionContract = new Contract(
            SETTINGS.collection,
            CollectionABI,
            signer
        );
        const level = await collectionContract.levels(signer.address);
        if (parseInt(level) > 1) {
            setUserLevel(level.toString());

        }
        if (parseInt(level) == 2) setLevelModelUrl("https://node.fingermonkeys.com/planet1.png");
        if (parseInt(level) == 3) setLevelModelUrl("https://node.fingermonkeys.com/planet2.png");
        if (parseInt(level) == 4) setLevelModelUrl("https://node.fingermonkeys.com/planet3.png");
        if (parseInt(level) == 5) setLevelModelUrl("https://node.fingermonkeys.com/planet4.png");
        if (parseInt(level) == 1337) setLevelModelUrl("https://node.fingermonkeys.com/planet1337.png");
        setLoading(false);
    };

    useEffect(() => {
        if (isConnected) {
            getUserLevel();
        } else {
            setLoading(false);
        }
    }, [isConnected, provider]);


    if (loading) {
        return (
            <div className="pokemon-card">
                <div className="text-center">
                    <center>
                        <img src={StripeLoaderImg} />
                    </center>
                </div>
            </div>
        );
    }


    return (
        <div className="text-center">

          
            <img variant="top" src={levelModelUrl} style={{ width:"80%", opacity: "1", padding: "20px", marginTop: "0px", marginBottom: "0px" }} />
           
           


          
                <Row className='mb-4'>
                    <Col><Button variant="primary buton butonyellow mt-md-2 mt-0" onClick={openBuyNode} className='buton' style={{height: "42.5px", fontSize: "15px" }}>
                        <BiShoppingBag className='mb-1' /> Store
                    </Button></Col>
                    <Col><Button variant="primary buton-secondary mt-md-2 mt-0" onClick={openCraft} className='buton' style={{ height: "42.5px", fontSize: "15px" }}>
                        <BiCustomize /> Craft
                    </Button></Col>
                </Row>
 






        </div>
    );
};

export default LevelComponent;
