import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Lore1Img from '../lore1.jpeg';
import Lore2Img from '../lore2.jpeg';
import Lore4Img from '../lore4.jpeg';
import Lore5Img from '../lore5.jpeg';

const QvrseLore = () => {
  return (
    <Container className="px-0 mt-0">
      <Row className="mb-4">
        <Col xs={12} className='mb-md-3 mb-2 mt-md-4 mt-0'>
          <h2 className='text-start'>Fmverse: The Genesis</h2>
        </Col>
        <Col md={6}>
          <Card className='mb-3' style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
            <Card.Img variant="top" src={Lore1Img} alt="Genesis of Fmverse" />
            <Card.Body>
              <Card.Title>The Origin</Card.Title>
              <Card.Text>
                Eons ago, in the infancy of the universe, a team of visionaries imagined a realm where imagination and reality merged. To offer a dimension with endless possibilities, where individuals could shape their land and leave a lasting mark on the digital tapestry.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className='mb-3' style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
            <Card.Img variant="top" src={Lore2Img} alt="Call to Adventure" />
            <Card.Body>
              <Card.Title>The Call to Adventure</Card.Title>
              <Card.Text>
                <br />
                <br />
                Comming soon...
                <br />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className='mb-3' style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
            <Card.Img variant="top" src={Lore4Img} alt="The Community of Creators" />
            <Card.Body>
              <Card.Title>The Community of Creators</Card.Title>
              <Card.Text>
                <br />
                <br />
                Comming soon...
                <br />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className='mb-4' style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
            <Card.Img variant="top" src={Lore5Img} alt="The Legacy" />
            <Card.Body>
              <Card.Title>The Legacy</Card.Title>
              <Card.Text>
                As each user carves their legacy in Fmverse, they contribute to a grand mosaic of creativity and innovation. The legends of the most remarkable planets and their creators are celebrated, inspiring new generations to push the boundaries of what is possible.
                <br /><br />
                Welcome to Fmverse, where your imagination is the only limit. Embark on your journey, craft your land, and ascend to greatness in this unparalleled virtual reality space. The adventure begins now.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

      </Row>

      {/* 
 <Row className="mb-4">
        <Col>
          <Card className='mb-3' style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
            <Card.Img variant="top" src={Lore2Img} alt="Call to Adventure" />
            <Card.Body>
              <Card.Title>The Call to Adventure</Card.Title>
              <Card.Text>
                Each new inhabitant of Fmverse begins their journey on a humble, barren planet, a blank canvas waiting to be transformed. These nascent lands hold the potential for greatness, awaiting the touch of their creators. Through exploration, resource gathering, and the mastery of crafting, users can evolve their planets, imbuing them with unique features, landscapes, and ecosystems.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
     
      
        <Col>
          <Card className='mb-3' style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
            <Card.Img variant="top" src={Lore4Img} alt="Community of Creators" />
            <Card.Body>
              <Card.Title>The Community of Creators</Card.Title>
              <Card.Text>
                Fmverse is not a solitary journey. It is a vibrant community where creators connect, collaborate, and compete. Users can visit each other's planets, share knowledge, trade resources, and embark on joint ventures. The collective growth of the community propels Fmverse to new heights, making it a dynamic, ever-evolving universe.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
    
        <Col>
          <Card className='mb-3' style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
            <Card.Img variant="top" src={Lore5Img} alt="Legacy" />
            <Card.Body>
              <Card.Title>The Legacy</Card.Title>
              <Card.Text>
                As each user carves their legacy in Fmverse, they contribute to a grand mosaic of creativity and innovation. The legends of the most remarkable planets and their creators are celebrated, inspiring new generations to push the boundaries of what is possible.
                <br /><br />
                Welcome to Fmverse, where your imagination is the only limit. Embark on your journey, craft your land, and ascend to greatness in this unparalleled virtual reality space. The adventure begins now.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    */}
    </Container>
  );
};

export default QvrseLore;
