
const SETTINGS = {
    nodeContract:"0x1841e2322dc688a3C5747DFccBf3A218D6EcC840",
    mainContract:"0x1e30A93ABB70aBB5041026145C1887781f0a40e0",
    usdtAddress:"0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    tokenAddress:"0x467E89776E227F5B14CBFd57D045c17f525f0Ce5",
    stakingContractQtech:"0x41618517cd4474938EE0b7ddA183037735f4471E",
    stakingContract:"0xcc6B432dE55622c0F56281Bc9bFD5B35dC20D92e",
    owner:["0xDBCf77D526d7a2023b5751C538D096c66E2cc602","0xB01bDd8Dda032b447f73b7CbE516aad51eaa2Fc5","0xe525668fE8a995207f7dCacAA6Ab08635F26a59A"],

    nativeSymbol: "MATIC",
    nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    usdtIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    tokenSymbol: "FMT",
    collectionSymbol: "FLND",
    openSeaLink: "",
    spatialLink: "",
    collection: "0x24849E4BbCAb97eA6Ec55FDB46cB5826C593C998",
    dataFetcherContract: "0xE034E7912bcea563F7636E2aB302C9d3C097Ce6B",
    DAILY_TOKENS: 22.83,
    MAX_SUPPLY: 15000,
    AP_PRICE: 3,
    END_TIME: 1852807838 * 1000,
    NEXT_AIRDROP: 1733011200 * 1000,
    NODE_INFO:{
                "1":{
                airdrop_power: 1,
                title: 'Land #1', 
                level: 'Level 1', 
                dailyRewards: 0.345, 
                refferalPercent: 0, 
                imgUrl: "https://node.fingermonkeys.com/planet.png", 
                craftElements:[],
                price: 25,
                buyCurrency:"USDC"
            },
        "2":{
                airdrop_power: 3,
                title: 'Land #2', 
                level: 'Level 2', 
                dailyRewards: 0.345 * 2, 
                refferalPercent: 2, 
                imgUrl: "https://node.fingermonkeys.com/planet1.png", 
                craftElements:["https://node.fingermonkeys.com/planet.png", "https://node.fingermonkeys.com/planet.png"],
                price: 0,
                buyCurrency:""
            },
        "3":{
                airdrop_power: 7,
                title: 'Land #3',
                level: 'Level 3',  
                dailyRewards: 0.345 * 4, 
                refferalPercent: 3, 
                imgUrl: "https://node.fingermonkeys.com/planet2.png", 
                craftElements:["https://node.fingermonkeys.com/planet1.png", "https://node.fingermonkeys.com/planet1.png", "https://node.fingermonkeys.com/planet10.png"],
                price: 0,
                buyCurrency:""
            },
        "4":{
                airdrop_power: 16,
                title: 'Land #4', 
                level: 'Level 4', 
                dailyRewards: 0.345 * 8, 
                refferalPercent: 5, 
                imgUrl: "https://node.fingermonkeys.com/planet3.png", 
                craftElements:["https://node.fingermonkeys.com/planet2.png", "https://node.fingermonkeys.com/planet2.png", "https://node.fingermonkeys.com/planet10.png", "https://node.fingermonkeys.com/planet20.png"],
                price: 0,
                buyCurrency:""
            },
        "5":{
                airdrop_power: 50,
                title: 'Land #5', 
                level: 'Level 5', 
                dailyRewards: 0.345 * 16, 
                refferalPercent: 8, 
                imgUrl: "https://node.fingermonkeys.com/planet4.png", 
                craftElements:["https://node.fingermonkeys.com/planet3.png", "https://node.fingermonkeys.com/planet3.png", "https://node.fingermonkeys.com/planet20.png", "https://node.fingermonkeys.com/planet42.png"],
                price: 0,
                buyCurrency:""
            },
        "1337":{
                airdrop_power: 420,
                title: 'Land #1337', 
                level: 'Level 1337', 
                dailyRewards: 0.345 * 32, 
                refferalPercent: 10, 
                imgUrl: "https://node.fingermonkeys.com/planet1337.png", 
                craftElements:["https://node.fingermonkeys.com/planet4.png", "https://node.fingermonkeys.com/planet4.png", "https://node.fingermonkeys.com/planet42.png", "https://node.fingermonkeys.com/planet42.png"],
                price: 0,
                buyCurrency:""
            },
        "20":{
                airdrop_power: 2,
                title: 'Pina Colada', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.fingermonkeys.com/planet20.png", 
                craftElements:[],
                price: 20,
                buyCurrency:"FMT"
            },
        "42":{
                airdrop_power: 4,
                title: 'Mapinguari', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.fingermonkeys.com/planet42.png", 
                craftElements:[],
                price: 42,
                buyCurrency:"FMT"
            },
        "10":{
                airdrop_power: 0,
                title: 'Juma', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.fingermonkeys.com/planet10.png", 
                craftElements:[],
                price: 10,
                buyCurrency:"FMT"
            },
        "11":{
                airdrop_power: 1,
                title: 'Lagoon', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.fingermonkeys.com/planet11.png", 
                craftElements:["https://node.fingermonkeys.com/planet10.png", "https://node.fingermonkeys.com/planet10.png", "https://node.fingermonkeys.com/planet10.png", "https://node.fingermonkeys.com/planet10.png"],
                price: 0,
                buyCurrency:""
            },
        "12":{
                airdrop_power: 3,
                title: 'Fruits', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.fingermonkeys.com/planet12.png", 
                craftElements:["https://node.fingermonkeys.com/planet11.png", "https://node.fingermonkeys.com/planet11.png", "https://node.fingermonkeys.com/planet10.png", "https://node.fingermonkeys.com/planet10.png"],
                price: 0,
                buyCurrency:""
            },
        "14":{
                airdrop_power: 10,
                title: 'Yeseraye', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.fingermonkeys.com/planet14.png", 
                craftElements:["https://node.fingermonkeys.com/planet12.png", "https://node.fingermonkeys.com/planet12.png"],
                price: 0,
                buyCurrency:""
            },
       
    },
    CRAFT_DICT:{
        "1_1_0_0": ["https://node.fingermonkeys.com/planet1.png", 2],
        "2_2_10_0": ["https://node.fingermonkeys.com/planet2.png", 3],
        "3_3_10_20": ["https://node.fingermonkeys.com/planet3.png", 4],
        "4_4_20_42": ["https://node.fingermonkeys.com/planet4.png", 5],
        "5_5_42_42": ["https://node.fingermonkeys.com/planet1337.png", 1337],
        "10_10_10_10": ["https://node.fingermonkeys.com/planet11.png", 11],
        "11_11_10_10": ["https://node.fingermonkeys.com/planet12.png", 12],
        "12_12_0_0": ["https://node.fingermonkeys.com/planet14.png", 14],
      }


};
export default SETTINGS;
