import React, { useState } from 'react';
import { Row, Col, Modal, Button, Image, Badge } from 'react-bootstrap';
import SETTINGS from '../SETTINGS';

const LevelsLegend = ({ currentLevel }) => {
  const levels = ["1", "2", "3", "4", "5", "1337"];
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isMobile] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (level) => {
    if (parseInt(level) === 1) return;
    const content = SETTINGS.NODE_INFO[level]; // Assuming content is in SETTINGS.NODE_INFO
    setModalContent(content);
    setShow(true);
  };

  return (
      <>
        {/* Levels */}
        <div className={`mb-3 scrollable-row`}>
          <Row className={`${isMobile ? 'flex-nowrap' : ''}`}>
            {levels.map((level, index) => (
                <Col key={index} xs={6} sm={4} md={3} lg={2} style={{ display: parseInt(currentLevel) <= parseInt(level) ? "" : "" }}>
                  <div
                      className='level-card'
                      style={{ padding: "10px" }}
                      onClick={() => handleShow(level)}
                  >
                    <img
                        src={SETTINGS.NODE_INFO[level].imgUrl}
                        className='levelImg'
                        style={{
                          filter: parseInt(currentLevel) >= parseInt(level) ? "" : "grayscale(100%)",
                          opacity: parseInt(currentLevel) >= parseInt(level) ? 1 : "0.6"
                        }}
                    />
                    <h6 className='levelTitle' style={{
                      marginTop: "8px",
                      color: parseInt(currentLevel) >= parseInt(level) ? "#FFFFFF" : "#bbbbbb",
                      backgroundColor: parseInt(currentLevel) >= parseInt(level) ? "" : "#191A15"
                    }}>
                      <small>Level</small> {level}
                    </h6>
                  </div>
                </Col>
            ))}
          </Row>
        </div>

        {/* Modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton className='pt-0'>
            <Modal.Title>
              <h3 className='mb-0'>
                <Image src={modalContent?.imgUrl} rounded className="me-2" style={{ width: '80px', height: '80px' }} /> {modalContent?.level}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='pt-0'>
            <h5 className='mb-3'>Rewards:</h5>
            <ul className='mb-4'>
              <li>
                <p className='small'><Badge pill bg="info" className='me-1 px-3'> {modalContent?.refferalPercent} % referral </Badge> in USDC</p>
              </li>
              <li>
                <p className='small'>
                  <b>1x</b>
                  <Image src={SETTINGS.NODE_INFO["10"].imgUrl} rounded className="me-1 mb-1" style={{ width: '40px', height: '40px' }} />
                  for you {modalContent?.refferalPercent >= 5 && (<> <b>1x</b> <Image src={SETTINGS.NODE_INFO["10"].imgUrl} rounded className="me-1 mb-1" style={{ width: '40px', height: '40px' }} /> for your friend </>)} each time your referral code is used to buy <b>LAND #1.</b>
                </p>
              </li>
            </ul>
            <hr />
            <div>
              <p className='mb-3 mt-3 pt-2'>
                Craft
                <Image src={modalContent?.imgUrl} rounded className="me-2 mb-2" style={{ width: '40px', height: '40px', marginLeft: "10px" }} />
                from
                {modalContent?.craftElements.map((imgMod, index) => (
                    <Image key={index} src={imgMod} rounded className="ms-2 mb-2" style={{ width: '40px', height: '40px' }} />
                ))}
                <b className='ms-1'>to unlock {modalContent?.level}.</b>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className='pb-1 pt-3'>
            <Button variant="buton buton-secondary" className="buton" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
};

export default LevelsLegend;
